import React, { Component } from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import styled from "styled-components"
import { v4 } from "uuid"

import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import { ArrowLeftIcon, ArrowRightIcon } from "../../components/Icons"

const CardArticle = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  position: relative;
`

const CardArticleFigure = styled.div`
  position: relative;
  margin-bottom: 10px;
`
const CardArticleBody = styled.div`
  position: relative;
  z-index: 1;
  width: 100%;
`
// const CardArticleTitle = styled.div`
//   font-weight: 400;
//   font-size: 14px;
//   line-height: 24px;
//   color: #fff;
// `
const SlickSlider = styled.div`
  padding: 0;
  max-width: 1920px;
  margin: 0 auto;
  width: 100%;
  position: relative;
  z-index: 1;
`
const SlickItem = styled.div`
  display: block !important;
  outline: none;
  max-width: 100%;
  padding: 0 5px;
`
const SlickArrowTrack = styled.div`
  position: absolute;
  right: 0;
  z-index: 10;
  top: -50px;
  width: 60px;
  height: 30px;
  &:hover {
    cursor: pointer;
  }
  .arrow-slick {
    position: absolute;
    cursor: pointer;
    width: 30px;
    height: 30px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
      fill: #fff;
      width: 16px;
      height: 14px;
    }
    &:hover {
      svg {
        fill: #fff;
      }
    }
  }
`
const LeftArrow = styled.div`
  left: 0;
`
const RightArrow = styled.div`
  right: 0;
`

function CircleNextArrow(props) {
  const { className, style, onClick } = props
  return (
    <div
      className={className}
      style={{ ...style, display: "flex" }}
      onClick={onClick}
      aria-hidden="true"
    >
      <ArrowRightIcon />
    </div>
  )
}

function CirclePrevArrow(props) {
  const { className, style, onClick } = props
  return (
    <div
      className={className}
      style={{ ...style, display: "flex" }}
      onClick={onClick}
      aria-hidden="true"
    >
      <ArrowLeftIcon />
    </div>
  )
}

export default class AboutCarousel extends Component {
  constructor(props) {
    super(props)
    this.next = this.next.bind(this)
    this.previous = this.previous.bind(this)
  }
  next() {
    this.slider.slickNext()
  }
  previous() {
    this.slider.slickPrev()
  }
  render() {
    const { data } = this.props
    const settings = {
      slidesToShow: 3,
      slidesToScroll: 1,
      nextArrow: <CircleNextArrow />,
      prevArrow: <CirclePrevArrow />,
      arrow: false,
      centerMode: true,
      centerPadding: "100px",
      infinite: true,
      focusOnSelect: true,
      responsive: [
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 1,
            centerPadding: "250px",
          },
        },
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 1,
            centerPadding: "150px",
          },
        },
        {
          breakpoint: 576,
          settings: {
            slidesToShow: 1,
            centerPadding: "30px",
          },
        },
      ],
    }

    return (
      <SlickSlider>
        <div className="container">
          <SlickArrowTrack>
            <LeftArrow className="arrow-slick" onClick={this.previous}>
              <ArrowLeftIcon />
            </LeftArrow>
            <RightArrow className="arrow-slick" onClick={this.next}>
              <ArrowRightIcon />
            </RightArrow>
          </SlickArrowTrack>
        </div>
        <Slider {...settings} ref={c => (this.slider = c)}>
          {data.map(item => (
            <SlickItem key={v4()}>
              <CardArticle className="card">
                <CardArticleBody className="card-body">
                  <CardArticleFigure className="card-img">
                    <GatsbyImage
                      image={item.gatsbyImageData}
                      alt={item.title}
                    />
                  </CardArticleFigure>
                  {/* <CardArticleTitle className="card-title">
                    {item.title}
                  </CardArticleTitle> */}
                </CardArticleBody>
              </CardArticle>
            </SlickItem>
          ))}
        </Slider>
      </SlickSlider>
    )
  }
}
