import React from "react"
import styled from "styled-components"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { graphql } from "gatsby"
import {
  Section,
  SectionPageTitle,
  SectionTitle,
  SectionDescription,
  MarkdownContent,
} from "../components/Section"
import CustomBuildingQuote from "../components/CustomBuildingQuote"
import ProcessSection from "../components/Process"
import WhyChooseSection from "../components/WhyChoose"
import AboutCarousel from "../components/AboutCarousel"
import BreakpointUp from "../components/Media/BreakpointUp"
import { StaticImage } from "gatsby-plugin-image"

const SectionGrid = styled.div`
  display:flex;
  flex-wrap:wrap;
  margin:0 -15px;
`
const SectionLeftGrid = styled.div`
  position:relative;
  width:100%;
  padding:0 15px;
  @media(min-width:992px){
    flex:0 0 60%;
    max-width:60%;
  }  
`
const SectionRightGrid = styled.div`
  position:relative;
  width:100%;
  padding:0 15px;
  display:none;
  @media(min-width:992px){
    display:flex;
    flex:0 0 40%;
    max-width:40%;  
  }  
`
const FigureImage = styled.div`
  border-radius:8px;
  overflow:hidden;
`
const SectionTopHeader = styled.div`
  position: relative;
  margin-bottom: 60px;
  @media(min-width:576px){
    padding-left:100px;    
  }
  @media(min-width:992px){
    padding-left:150px;
  }
  @media(min-width:1200px){
    padding-left:200px;
  }
`
const SectionRotateTitle = styled.span`
  display: inline-block;
  color: rgba(255, 255, 255, 0.15);
  font-size: 28px;
  line-height: 38px;
  font-weight: 900;
  ${BreakpointUp.sm`                     
        position:absolute;
        left:0;
        top:50%;
        transform: translateY(-50%) rotate(-90deg);
    `}
  ${BreakpointUp.md`
		font-size: 38px;
		line-height: 48px;
	`}
    ${BreakpointUp.lg`
		font-size: 48px;
		line-height: 58px;
	`}
`

const AboutPage = ({ data, location }) => {
  const pageData = data.contentfulAboutUsPage

  const processSectionData = {
    label: pageData.processSectionLabel,
    title: pageData.processSectionTitle,
    processes: pageData.processFeatures,
  }

  const whyChooseSectionData = {
    label: pageData.whyChooseSectionLabel,
    title: pageData.whyChooseSectionTitle,
    tabData: pageData.whyChooseSectionFeatures,
  }
  return (
    <Layout location={location}>
      <Seo title={pageData.metaTitle} description={pageData.metaDescription.metaDescription} />
      <Section
        pt="220px"
        pb="90px"
        xpt="120px"
        xpb="60px"
        bgColor="#0B619B"
        className="circle-right top-0 sm"
      >
        <div className="container">
          <SectionGrid>
            <SectionLeftGrid>
              <SectionTopHeader>
                <SectionRotateTitle>{pageData.pageName}</SectionRotateTitle>
                <SectionPageTitle
                  textAlign="left"
                  color="#fff"
                  maxWidth="950px"
                  ml="0"
                >
                  {pageData.heroTitle}
                </SectionPageTitle>
                <SectionDescription
                  textAlign="left"
                  color="rgba(255,255,255,0.8)"
                  maxWidth="950px"
                  ml="0"
                >
                  <div
                    dangerouslySetInnerHTML={{
                      __html: pageData.heroDescription.childMarkdownRemark.html,
                    }}
                  />
                </SectionDescription>
              </SectionTopHeader>
            </SectionLeftGrid>
            <SectionRightGrid>
              <FigureImage><StaticImage src="../images/manufacturing-unit-arkansas.jpg" alt="manufacturing-unit-arkansas" /></FigureImage>
            </SectionRightGrid>
          </SectionGrid>
        </div>
        <AboutCarousel data={pageData.heroImages} />
      </Section>
      <Section pt="90px" pb="90px" xpt="60px" xpb="60px" bgColor="#fff">
        <div className="container mw-1120">
          <MarkdownContent>
            <div
              dangerouslySetInnerHTML={{
                __html: pageData.content.childMarkdownRemark.html,
              }}
            />
          </MarkdownContent>
        </div>
      </Section>
      <Section pt="90px" pb="90px" xpt="60px" xpb="60px" bgColor="#F2F4F9">
        <div className="container">
          <SectionTitle maxWidth="700px" mb="60px">
            Get Your Custom Building Quote in Just a few Clicks
          </SectionTitle>
          <CustomBuildingQuote states={data.allContentfulState.edges} location={location} />
        </div>
      </Section>
      <WhyChooseSection data={whyChooseSectionData} />
      <ProcessSection data={processSectionData} />
    </Layout>
  )
}

export default AboutPage

export const pageQuery = graphql`
  query AboutPageQuery {
    contentfulAboutUsPage {
      pageName
      metaTitle
      metaDescription {
        metaDescription
      }
      heroTitle
      heroDescription {
        childMarkdownRemark {
          html
        }
      }
      heroImages {
        title
        gatsbyImageData
      }
      content {
        childMarkdownRemark {
          html
        }
      }
      whyChooseSectionLabel
      whyChooseSectionTitle
      whyChooseSectionFeatures {
        label
        title
        description {
          childMarkdownRemark {
            html
          }
        }
        image {
          title
          gatsbyImageData
        }
      }
      processSectionLabel
      processSectionTitle
      processFeatures {
        label
        title
        description {
          description
        }
        image {
          title
          gatsbyImageData
        }
        buttonText
        buttonLink
      }
    }
    allContentfulState(sort: { order: ASC, fields: name }) {
      edges {
        node {
          name
        }
      }
    }
  }
`
